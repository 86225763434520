var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-layout',{staticClass:"mlayout"},[_c('a-layout',[_c('a-layout-content',{staticClass:"indexLayoutContent",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"page flex-col"},[_c('div',{class:`bardiv ${_vm.barclass}`},[_c('div',{staticClass:"barlist"},[_vm._l((_vm.blinks),function(item,index){return [_c('a',{key:index,staticClass:"bla"},[_c('div',{key:index,staticClass:"blitem",on:{"click":() => {
                        _vm.goDiv(item.class, item.url);
                      }}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"lined"},[_c('img',{staticClass:"line",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/imgs/line.png")}})])])])]}),_c('div',{staticClass:"blitem justify-between",staticStyle:{"flex-direction":"row"}},[(_vm.lag == 'en')?_c('span',{on:{"click":() => {
                      _vm.changelag('');
                    }}},[_vm._v("中")]):_c('span',{staticStyle:{"color":"rgba(231, 55, 74, 1)"}},[_vm._v("中")]),_vm._v("   /   "),(_vm.lag == 'en')?_c('span',{staticStyle:{"color":"rgba(231, 55, 74, 1)"}},[_vm._v("EN")]):_c('span',{on:{"click":() => {
                      _vm.changelag('en');
                    }}},[_vm._v("EN")])])],2),_c('div',{staticClass:"toplan"},[_c('div',{staticClass:"image-wrapper_1 flex-col justify-between w-logo"},[(_vm.from == 'index')?_c('img',{staticClass:"w-logo-img",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/imgs/logo.png")}}):_vm._e(),(_vm.from == 'tech')?_c('img',{staticClass:"w-logo-img",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/imgs/logo-2.png")}}):_vm._e()]),_c('div',{staticClass:"closebar",on:{"click":_vm.closeBardiv}},[_c('img',{attrs:{"src":require("../assets/imgs/icon-close2.png")}})])]),_c('div',{staticClass:"bltouch",on:{"click":_vm.closeBardiv}})]),(_vm.barclass == 'closethis')?_c('div',{staticClass:"group_3 flex-row",staticStyle:{"background-color":"#000"}},[_c('div',{staticClass:"toplan"},[_c('div',{staticClass:"image-wrapper_1 flex-col justify-between w-logo wllogo"},[(_vm.from == 'index')?_c('router-link',{attrs:{"to":_vm.lag == 'en' ? `/index/en` : `/index`}},[_c('img',{staticClass:"w-logo-img",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/imgs/logo.png")}})]):_vm._e(),(_vm.from == 'tech')?_c('router-link',{attrs:{"to":_vm.lag == 'en' ? `/tech/en` : `/tech`}},[_c('img',{staticClass:"w-logo-img",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/imgs/logo-2.png")}})]):_vm._e()],1),_c('div',{staticClass:"blinks justify-end"},[_vm._l((_vm.blinks),function(item,index){return [(index < _vm.blinks.length - 1)?[(_vm.bselectindex != index)?_c('span',{key:index,staticClass:"item flex-col justify-between",style:(index == 5 ? `width:84px` : ``),on:{"mouseover":() => {
                          _vm.blinkshandleHover(index);
                        },"touchstart":() => {
                          _vm.blinkshandleHover(index);
                        }}},[_c('span',{staticClass:"itemtext"},[_vm._v(_vm._s(item.title))])]):_vm._e(),(_vm.bselectindex == index)?_c('span',{key:index,staticClass:"itemf flex-col justify-between",style:(index == 5 ? `width:84px` : ``),on:{"mouseleave":_vm.blinkshandleLeave,"click":() => {
                          _vm.goDiv(item.class, item.url);
                        }}},[_c('span',{staticClass:"itemtext"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"lined",style:(index == 5 ? `width:84px` : ``)},[_c('img',{staticClass:"line",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/imgs/line.png")}})])]):_vm._e()]:_vm._e()]}),_c('span',{class:`${
                    _vm.topbarOpacity >= 1 ? 'itemfsf' : 'itemfs'
                  } flex-col justify-between`,on:{"click":() => {
                      _vm.goDiv(
                        _vm.blinks[_vm.blinks.length - 1].class,
                        _vm.blinks[_vm.blinks.length - 1].url
                      );
                    }}},[_c('span',{staticClass:"itemtext"},[_vm._v(_vm._s(_vm.blinks[_vm.blinks.length - 1].title))])]),_c('span',{class:`${
                    _vm.lag == 'en' ? 'item' : 'itemf'
                  } flex-col justify-between`,on:{"click":() => {
                      _vm.changelag('');
                    }}},[_c('span',{staticClass:"itemtext"},[_vm._v("中")])]),_c('span',{staticClass:"item flex-col justify-between",staticStyle:{"margin-left":"5px"}},[_c('span',{staticClass:"itemtext"},[_vm._v("/")])]),_c('span',{class:` ${
                    _vm.lag == 'en' ? 'itemf' : 'item'
                  }  flex-col justify-between`,staticStyle:{"margin-left":"5px"},on:{"click":() => {
                      _vm.changelag('en');
                    }}},[_c('span',{staticClass:"itemtext"},[_vm._v("EN")])])],2),(_vm.barclass == 'closethis')?_c('div',{staticClass:"showbar",on:{"click":_vm.openBardiv}}):_vm._e()]),_c('div',{staticClass:"darkback",style:({ opacity: _vm.topbarOpacity })})]):_vm._e(),_c('div',{staticClass:"searcharea group_10 flex-row justify-between",staticStyle:{"margin-top":"16rem"}},[_c('div',{staticClass:"searchdiv"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchkey),expression:"searchkey"}],attrs:{"type":"text","placeholder":_vm.lag == 'en'
                    ? 'Enter the desired information...'
                    : `输入想了解的…`,"id":"searchkeyinput"},domProps:{"value":(_vm.searchkey)},on:{"input":function($event){if($event.target.composing)return;_vm.searchkey=$event.target.value}}}),_c('div',{staticClass:"searchbtn",on:{"click":_vm.searchplist}},[_c('div',{staticClass:"search"})])])]),_c('div',{staticClass:"group_10 flex-col justify-between",staticStyle:{"margin-top":"8rem","width":"142rem"}},[_c('div',{staticClass:"mkdiv flex-row justify-between",staticStyle:{"width":"142rem"}},[_c('a',{staticClass:"mkitema"},[_c('div',{class:`mkitem ${
                    _vm.thisclass2 == '' ? 'selected' : ''
                  } flex-col`,staticStyle:{"padding-left":"3rem","padding-right":"3rem"},on:{"click":() => {
                      _vm.selectclass2('');
                    }}},[(_vm.lag == 'en')?_c('span',{staticClass:"mktext"},[_vm._v("ALL")]):_c('span',{staticClass:"mktext"},[_vm._v("全部")])])]),_vm._l((_vm.mkinds),function(item,index){return [_c('a',{key:index,staticClass:"mkitema"},[_c('div',{class:`mkitem flex-col  ${
                      _vm.thisclass2 == item.id ? 'selected' : ''
                    }`,on:{"click":() => {
                        _vm.selectclass2(item.id);
                      }}},[_c('span',{staticClass:"mktext",domProps:{"innerHTML":_vm._s(item.name)}})])])]})],2),_c('div',{staticClass:"mkdiv mkeydiv flex-row justify-between",staticStyle:{"display":"none"}},[_c('a',{staticClass:"mkitema"},[_c('div',{class:`mkitem ${
                    _vm.thiskeysindex == '-1' ? 'selected' : ''
                  } flex-col`,staticStyle:{"padding-left":"3rem","padding-right":"3rem"},on:{"click":() => {
                      _vm.selectkeyword('', '-1');
                    }}},[(_vm.lag == 'en')?_c('span',{staticClass:"mktext"},[_vm._v("ALL")]):_c('span',{staticClass:"mktext"},[_vm._v("全部")])])]),_vm._l((_vm.mkeys),function(item,index){return [_c('a',{key:index,staticClass:"mkitema"},[_c('div',{class:`mkitem flex-col  ${
                      _vm.thiskeysindex == index ? 'selected' : ''
                    }`,on:{"click":() => {
                        _vm.selectkeyword(item, index);
                      }}},[_c('span',{staticClass:"mktext",domProps:{"innerHTML":_vm._s(item)}})])])]})],2)]),_c('div',{staticClass:"pplist flex-row justify-between",staticStyle:{"margin-bottom":"10rem"}},[(_vm.dataloading)?_c('svg',{staticStyle:{"width":"8rem","height":"10rem","position":"absolute","top":"50%","left":"50%","margin-top":"-5rem","margin-left":"-4rem","enable-background":"new 0 0 50 50"},attrs:{"version":"1.1","id":"Layer_1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","width":"8rem","height":"10rem","viewBox":"0 0 24 30","xml:space":"preserve"}},[_c('rect',{attrs:{"x":"0","y":"13","width":"4","height":"5","fill":"#CCCCCC"}},[_c('animate',{attrs:{"attributeName":"height","attributeType":"XML","values":"5;21;5","begin":"0s","dur":"0.6s","repeatCount":"indefinite"}}),_c('animate',{attrs:{"attributeName":"y","attributeType":"XML","values":"13; 5; 13","begin":"0s","dur":"0.6s","repeatCount":"indefinite"}})]),_c('rect',{attrs:{"x":"10","y":"13","width":"4","height":"5","fill":"#CCCCCC"}},[_c('animate',{attrs:{"attributeName":"height","attributeType":"XML","values":"5;21;5","begin":"0.15s","dur":"0.6s","repeatCount":"indefinite"}}),_c('animate',{attrs:{"attributeName":"y","attributeType":"XML","values":"13; 5; 13","begin":"0.15s","dur":"0.6s","repeatCount":"indefinite"}})]),_c('rect',{attrs:{"x":"20","y":"13","width":"4","height":"5","fill":"#CCCCCC"}},[_c('animate',{attrs:{"attributeName":"height","attributeType":"XML","values":"5;21;5","begin":"0.3s","dur":"0.6s","repeatCount":"indefinite"}}),_c('animate',{attrs:{"attributeName":"y","attributeType":"XML","values":"13; 5; 13","begin":"0.3s","dur":"0.6s","repeatCount":"indefinite"}})])]):_vm._e(),(_vm.data.length > 0)?[_vm._l((_vm.data),function(item,index){return [_c('div',{key:index,class:`ppitem ${_vm.ppindex == index ? 'ppselected' : ''}`,style:(`animation-delay: ${index * 0.1}s;`),on:{"mouseover":() => {
                      _vm.pphandleHover(index);
                    },"touchstart":() => {
                      _vm.pphandleHover(index);
                    },"mouseleave":_vm.pphandleLeave}},[_c('img',{class:`ppimg`,attrs:{"referrerpolicy":"no-referrer","src":`${_vm.baseUrl + item.imgary[0].url}`}}),_c('div',{staticClass:"block_6 flex-col",on:{"click":() => {
                        if (item.url == '') {
                          _vm.swSlideTo(item.swiperindex, item.id);
                        } else {
                          _vm.gowebsite(item.url);
                        }
                      }}},[_c('div',{staticClass:"ppinfo",staticStyle:{"margin-bottom":"0rem"}},[(_vm.lag == 'en' && item.enpname != '')?_c('span',{staticClass:"text_57_240227",staticStyle:{"color":"#333"},domProps:{"innerHTML":_vm._s(item.enpname)}}):_c('span',{staticClass:"text_57_240227",staticStyle:{"color":"#333"},domProps:{"innerHTML":_vm._s(item.pname)}}),(_vm.lag == 'en' && item.endes != '')?_c('span',{staticClass:"des_57_240227",staticStyle:{"color":"#333"},domProps:{"innerHTML":_vm._s(item.endes)}}):_c('span',{staticClass:"des_57_240227",staticStyle:{"color":"#333"},domProps:{"innerHTML":_vm._s(item.des)}})])])])]})]:_vm._e(),(_vm.nopplistshow)?[_c('div',{staticClass:"nopplist"},[_vm._v("- NONE -")])]:_vm._e()],2),_c('footer-index',{attrs:{"w750":_vm.w750,"basedata":_vm.basedata,"lag":_vm.lag}})],1),_c('div',{staticClass:"showppdiv"},[_c('div',{staticClass:"closesw"}),_c('div',{staticClass:"swiper-container sc"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._l((_vm.data),function(item,index){return [(item.url == '')?_c('div',{key:index,staticClass:"swiper-slide ss0"},[_c('div',{staticClass:"ss ss0"},[_c('div',{staticClass:"ssin"},[_c('div',{staticClass:"scontent"},[(_vm.lag == 'en' && item.enpname != '')?_c('div',{staticClass:"sstitle",domProps:{"innerHTML":_vm._s(item.enpname)}}):_c('div',{staticClass:"sstitle",domProps:{"innerHTML":_vm._s(item.pname)}}),(_vm.lag == 'en' && item.enkeywords != '')?_c('div',{staticClass:"ppkinds flex-row justify-start"},[_vm._l((item.enkeywords.split(
                              ','
                            )),function(item2,index2){return [_c('div',{key:index2,staticClass:"ppkitem flex-col"},[_c('span',{staticClass:"ppktext",domProps:{"innerHTML":_vm._s(item2)}})])]})],2):_c('div',{staticClass:"ppkinds flex-row justify-start"},[_vm._l((item.keywords.split(
                              ','
                            )),function(item2,index2){return [_c('div',{key:index2,staticClass:"ppkitem flex-col"},[_c('span',{staticClass:"ppktext",domProps:{"innerHTML":_vm._s(item2)}})])]})],2),(_vm.lag == 'en' && item.encontent != '')?_c('div',{staticClass:"ppcontent"},[(item.swiperindex == _vm.selectIndex)?_c('div',{domProps:{"innerHTML":_vm._s(item.encontent)}}):_vm._e()]):_c('div',{staticClass:"ppcontent"},[(item.swiperindex == _vm.selectIndex)?_c('div',{domProps:{"innerHTML":_vm._s(item.content)}}):_vm._e()])])])])]):_vm._e()]})],2),_c('div',{staticClass:"swiper-pagination"}),_c('div',{staticClass:"swiper-button-next"}),_c('div',{staticClass:"swiper-button-prev"})])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }